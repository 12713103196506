import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../utils/globals';

export const AuthContext = createContext();

export default function AuthProvider({ children }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [fullname, setFullname] = useState('');
  const [firstname, setFirstname] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);

  const [error, setError] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token);
  }, []); // No dependencies needed
  

  const runLogin = async (email, password) => {
    try {
      const response = await axios.post(`${BASE_URL}/auth/login`, { email, password });
      return { data: response.data, error: null };
    } catch (err) {
      console.error(err);
      return { data: null, error: 'Login failed. Please check your credentials.' };
    }
  };
  

  const login = async (email, password) => {
    const { data, error } = await runLogin(email, password);
  
    if (data) {
      localStorage.setItem('token', data.access_token);
      localStorage.setItem('fullname', data.user.fullname);
      localStorage.setItem('firstname', data.user.firstname);
      localStorage.setItem('user_id', data.user._id);
      localStorage.setItem('estate_id', data.user.estate_id);
      localStorage.setItem('house_id', data.user.house_id);
      localStorage.setItem('role', data.role); // Store role


      localStorage.removeItem("password");
  
      setIsLoggedIn(true);
      setFullname(data.user.fullname);
      setFirstname(data.user.firstname);
      setIsAdmin(data.role === 'ADMIN' || data.role === 'SUPER ADMIN'); // Check if admin
  
      setError('');
    } else {
      setError(error);
    }
    return { data, error };
  };
  

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('fullname');
    localStorage.removeItem('firstname');
    localStorage.removeItem('user_id');
    localStorage.removeItem('combinedData');

    setIsLoggedIn(false);
    setIsAdmin(false);
    setFullname('');
    setFirstname('');
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        fullname,
        firstname,
        login,
        logout,
        error,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
