import React, { useContext, useState } from 'react';
import { AuthContext } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { PropagateLoader } from 'react-spinners';
import image from '../image/irivs.png';
import { Link } from 'react-router-dom';

function Login() {
  const [email, setEmail] = useState(localStorage.getItem('email') || '');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async () => {
    setLoading(true);
    setError('');

    try {
      const { data } = await login(email, password);
      setLoading(false);

      if (data) {
        if (rememberMe) {
          localStorage.setItem('email', email);
        } else {
          localStorage.removeItem('email');
        }

        localStorage.setItem('token', data.access_token);
        localStorage.setItem('role', data.role);
        localStorage.setItem('user_id', data.user._id);

        if (data.role === 'SUPER ADMIN') {
          navigate('/superAdmin');
        } else if (data.role === 'ADMIN') {
          navigate('/panel');
        } else {
          navigate('/dashboard');
        }

        setEmail('');
        setPassword('');
      } else {
        setError('Wrong password or email');
      }
    } catch (error) {
      setLoading(false);
      setError('An error occurred. Please try again.');
    }
  };

  return (
    <div className="signup-page">
      <div className='signup---page'>
      <div className="sign--up">
        <div className="login---border">
          <div className="irivs">
            <img src={image} alt="Logo" />
          </div>
        </div>
        <div className="login-details">
          <h4> Authentication</h4>
          <p style={{ fontFamily: 'sans-serif', fontWeight: '300', fontSize: '15px' }}>
            Provide your email and password to access your account
          </p>
        </div>
        <form>
          <div className="login-form">
            <div className="input-group">
              <span className="input-group-text">Email</span>
              <input
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="form-control"
                placeholder="Email"
              />
            </div>
            <br />
            <div className="login_page input-group">
              <span className="input-group-text">Password</span>
              <input
                type={showPassword ? 'text' : 'password'}
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="form-control"
                placeholder="* * * * * * * * * *"
              />
              <FontAwesomeIcon
                icon={showPassword ? faEyeSlash : faEye}
                className="password-icon"
                onClick={togglePasswordVisibility}
              />
            </div>
            <div className="login--options d-flex mt-3">
              <div className="check--box d-flex">
                <input
                  type="checkbox"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                />
                <div className="remember-me">
                  <h5>Remember Me</h5>
                </div>
              </div>
              <Link className="forgot-line" to="/forgot-password">
                <h5>Forgot Password?</h5>
              </Link>
            </div>
            <div className="login-button">
              <button
                onClick={handleLogin}
                className="login--btn"
                type="button"
                disabled={loading}
              >
                {loading ? (
                  <div className="loading-spinner">
                    Processing...
                    <PropagateLoader color="blue" size={8} loading={true} />
                  </div>
                ) : (
                  'Login'
                )}
              </button>
            </div>
            {error && <p className="error" style={{ color: 'red' }}>{error}</p>}
          </div>
        </form>
      </div>
      </div>
    </div>
  );
}

export default Login;
